import { get, post, put, destroy } from '../api/api.js';

const baseUrl = 'users';

function indexUsers() {
  return get(baseUrl);
}

function showUsers(id) {
  return get(`${baseUrl}/${id}`);
}

function storeUsers(values) {
  return post(baseUrl, values);
}

function updateUsers(id, values) {
  return put(`${baseUrl}/${id}`, values);
}

function destroyUsers(id) {
  return destroy(`${baseUrl}/${id}`);
}

export { indexUsers, showUsers, storeUsers, updateUsers, destroyUsers }