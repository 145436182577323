import axios from "axios";
import store from "@/store/index.js";
import router from "@/router/index.js";

const axiosFormData = axios.create({
  baseURL: process.env.VUE_APP_HOST_API,
  timeout: 50000,
  responseType: "json",
  responseEncoding: "utf8",
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
});

axiosFormData.interceptors.request.use(
  (config) => {
    const access_token = store.getters["getAccessToken"];
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }

    return config;
  },
  (er) => {
    return Promise.reject(er.message);
  }
);

axiosFormData.interceptors.response.use(
  function (response) {
    console.log('has response', response)
    return response;
  },
  function (error) {

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      if (error.response.status == 401) {
        store.dispatch("logout").then(() => {
          router.push({ path: "/entrar" });
        });
      }

      if (error.response.status == 422) {
        let errorRules = {};

        const erros = error.response.data.errors;
        const keys = Object.keys(erros);
        keys.forEach((item) => {
          errorRules[item] = erros[item][0];
        });
  
        //throw errorRules;
        return Promise.reject(errorRules);
      }


      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

    return Promise.reject(error);
  }
)

export default axiosFormData;
