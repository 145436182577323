import { get, post, put, destroy } from '../api/api.js';

const baseUrl = 'prioritys';

function indexPrioritys() {
  return get(baseUrl);
}

function showPrioritys(id) {
  return get(`${baseUrl}/${id}`);
}

function storePrioritys(values) {
  return post(baseUrl, values);
}

function updatePrioritys(id, values) {
  return put(`${baseUrl}/${id}`, values);
}

function destroyPrioritys(id) {
  return destroy(`${baseUrl}/${id}`);
}

export { indexPrioritys, showPrioritys, storePrioritys, updatePrioritys, destroyPrioritys }