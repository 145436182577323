import { get, post, put, destroy } from '../api/api.js';

const baseUrl = 'ranks';

function indexRanks() {
  return get(baseUrl);
}

function showRanks(id) {
  return get(`${baseUrl}/${id}`);
}

function storeRanks(values) {
  return post(baseUrl, values);
}

function updateRanks(id, values) {
  return put(`${baseUrl}/${id}`, values);
}

function destroyRanks(id) {
  return destroy(`${baseUrl}/${id}`);
}

export { indexRanks, showRanks, storeRanks, updateRanks, destroyRanks }