<template>
  <v-card>
    <LoadingModal :visible="loading" />

    <v-card-title>
      <span class="headline">
        {{ data.id ? "Editar demanda" : "Cadastrar demanda" }}
      </span>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="Descrição"
                :disabled="enabledInputs"
                v-model="data.description"
                :error-messages="errors.description"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="classificacoes"
                item-text="name"
                item-value="id"
                v-model="data.rank_id"
                :error-messages="errors.rank_id"
                label="Classificação"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                multiple
                :items="clientes"
                v-model="data.customers"
                :error-messages="errors.customers"
                item-text="name"
                item-value="id"
                label="Solicitantes"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                multiple
                :items="users"
                v-model="data.users"
                :error-messages="errors.users"
                item-text="name"
                item-value="id"
                label="Participantes"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="status"
                item-value="id"
                item-text="name"
                v-model="data.status_id"
                :error-messages="errors.status_id"
                label="Status"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                :error-messages="errors.deadline"
                type="date"
                v-model="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                type="time"
                v-model="time"
                :error-messages="errors.deadline"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" md="5">
              <v-select
                :items="orgaos"
                v-model="data.agency_id"
                :error-messages="errors.agency_id"
                item-text="name"
                item-value="id"
                label="Externo"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                label="Prioridade"
                :items="prioritys"
                v-model="data.priority_id"
                :error-messages="errors.priority_id"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>
            <v-col md="12">
              <v-file-input
                v-model="data.arquivos"
                label="Arquivos"
                counter
                multiple
                show-size
                truncate-length="15"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12"> Historicos </v-col>
            <v-col md="12">
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    prepend-icon=""
                    :autofocus="true"
                    label="Titulo*"
                    :disabled="enabledInputs"
                    v-model="historico.title"
                    :error-messages="errors.title"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    prepend-icon=""
                    :autofocus="true"
                    label="Descrição*"
                    :disabled="enabledInputs"
                    v-model="historico.content"
                    :error-messages="errors.content"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-btn
                    class="no-text-transform"
                    color="success"
                    @click="saveHistory()"
                  >
                    Adicionar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-list three-line>
                  <template v-for="(item, index) in data.histories">
                    <v-list-item :key="item.titulo">
                      <v-list-item-avatar>
                        {{ index + 1 }}
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.title"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="item.content"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        outlined
        class="no-text-transform"
        color="primary"
        @click="cancel()"
      >
        Voltar
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn class="no-text-transform" color="primary" @click="saveData()">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ToastService from "@/services/ToastService";
import DemandaService from "@/services/Demanda/DemandaService";
import ArquivosService from "@/services/Arquivos/ArquivosService";
import axiosFormData from "../../plugins/axiosFormData";
import {
  indexRanks,
  indexAgencys,
  indexCustomers,
  indexUsers,
  indexDemandStatus,
  indexPrioritys,
} from "@/api/GetsApi.js";

export default {
  name: "UserForm",

  components: {},

  computed: {
    ...mapGetters({
      me: "getMe",
      permissions: "getPermissions",
      solicitacao: "getSolicitacao",
    }),
  },

  data: () => ({
    data: {
      cliente_id: [],
      user_id: [],
      histories: [],
      users: [],
      customers: [],
      description: "",
    },
    errors: {},
    clientes: [],
    users: [],
    histories: [],
    historico: {},
    orgaos: [],
    orgao: {},
    classificacoes: [],
    classificacao: {},
    listRoles: [],
    funcoes: [],
    select: {},
    prioritys: [],
    date: null,
    time: null,
    loading: false,
    enabledInputs: false,
    DemandaService: new DemandaService(""),
    ArquivosService: new ArquivosService(""),
    status: [],
  }),

  created() {
    this.loading = true;
  },

  mounted: async function() {
    if (this.solicitacao?.title) {
      this.data.classificacao = this.solicitacao.classificacao;
      this.data.cliente_id.push(parseInt(this.solicitacao.cliente_id));
      this.data.histories.push({
        title: this.solicitacao.title,
        body: this.solicitacao.body,
      });

      this.$store.dispatch("solicitacao", null);
    }

    await this.loadAllRelationships();
  },

  beforeDestroy() {
    this.$store.dispatch("solicitacao", null);
  },

  methods: {
    saveHistory() {
      this.data.histories.push(this.historico);
      this.historico = {};
      ToastService("Historico Adicionado", "success");
    },

    async saveData() {
      try {
        this.loading = true;
        this.enabledInputs = true;

        let data = new FormData();

        const properties = [
          "priority_id",
          "status_id",
          "agency_id",
          "rank_id",
          "description",
        ];

        properties.forEach((property) => {
          if (this.data[property]) {
            data.append(property, this.data[property]);
          }
        });

        if (this.date) {
          data.append("deadline", `${this.date} ${this.time}`);
        }

        if (this.data.arquivos) {
          this.data.arquivos.forEach((element) => {
            data.append("files[]", element);
          });
        }

        if (this.data.historico) {
          this.data.historico.forEach((element) => {
            data.append("histories[]", element);
          });
        }

        this.data.users.forEach((element) => {
          data.append("users[]", element);
        });

        this.data.customers.forEach((element) => {
          data.append("customers[]", element);
        });

        await axiosFormData.post("demands", data);

        ToastService("Ação realizada com sucesso.", "success");

        this.$root.$emit("updateNotify");

        this.$router.push({ path: "/app/demandas" });
      } catch (exception) {
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loading = false;
        this.enabledInputs = false;
      }
    },

    getDate(payload) {
      this.date = payload.date;

      this.data.deadline = payload.date + " " + this.time;
      console.log(this.data.deadline);
    },

    getTime(payload) {
      this.time = payload.time;
      this.data.deadline = this.date + " " + this.time;
      console.log(this.data.deadline);
    },

    async loadAllRelationships() {
      try {
        const [
          ranks,
          users,
          agencys,
          customers,
          prioritys,
          demandStatus,
        ] = await Promise.all([
          indexRanks(),
          indexUsers(),
          indexAgencys(),
          indexCustomers(),
          indexPrioritys(),
          indexDemandStatus(),
        ]);

        this.classificacoes = ranks;
        this.users = users;
        this.orgaos = agencys;
        this.clientes = customers;
        this.prioritys = prioritys;
        this.status = demandStatus;
      } catch (error) {
        console.log(error);
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$router.push({ path: "/app/demandas" });
    },
  },
};
</script>
