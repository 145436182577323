import instanceAxios from "../plugins/axios";

async function get(url) {
  const { data } = await instanceAxios.get(url);

  return data;
}

async function post(url) {
  const { data } = await instanceAxios.post(url);

  return data;
}

async function put(url) {
  const { data } = await instanceAxios.put(url);

  return data;
}

async function destroy(url) {
  const { data } = await instanceAxios.delete(url);

  return data;
}

export { get, post, put, destroy }