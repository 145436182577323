import { get, post, put, destroy } from '../api/api.js';

const baseUrl = 'agencys';

async function indexAgencys() {
  return get(baseUrl);
}

function showAgencys(id) {
  return get(`${baseUrl}/${id}`);
}

function storeAgencys(values) {
  return post(baseUrl, values);
}

function updateAgencys(id, values) {
  return put(`${baseUrl}/${id}`, values);
}

function destroyAgencys(id) {
  return destroy(`${baseUrl}/${id}`);
}

export { indexAgencys, showAgencys, storeAgencys, updateAgencys, destroyAgencys }