import ErrorService from "@/services/ErrorService";
import RequestService from "@/services/RequestService";
import axios from "axios";
import store from "@/store/index.js";

export default class DemandaService extends RequestService {
  constructor(baseEndpoint) {
    super(baseEndpoint);
  }

  index = async (endpoint) => {
    try {
      const { data } = await this.get(endpoint);

      return data;
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  show = async (id) => {
    try {
      const { data } = await this.get(id);

      return data;
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  storeOrUpdate = async (endpoint, params) => {
    try {
      await this.postOrPut(endpoint, params);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };


  store = async (form) => {
    try {
      const access_token = store.getters["getAccessToken"];
      var authorization = `${access_token}`;

      const { data } = await axios({
        method: "post",
        url: `https://api-wsjus.herokuapp.com/api/demanda`,
        data: form,
        headers: {
          "Content-Type": "application/json",
          "Authorization": authorization,
        },
      });
      //answare

      return data;
    } catch (exception) {
      throw ErrorService(exception);
    }
  };

  destroy = async (id) => {
    try {
      await this.delete(id);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };
}
