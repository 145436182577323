import { get, post, put, destroy } from '../api/api.js';

const baseUrl = 'demands/status';

function indexDemandStatus() {
  return get(baseUrl);
}

function showDemandStatus(id) {
  return get(`${baseUrl}/${id}`);
}

function storeDemandStatus(values) {
  return post(baseUrl, values);
}

function updateDemandStatus(id, values) {
  return put(`${baseUrl}/${id}`, values);
}

function destroyDemandStatus(id) {
  return destroy(`${baseUrl}/${id}`);
}

export { indexDemandStatus, showDemandStatus, storeDemandStatus, updateDemandStatus, destroyDemandStatus }