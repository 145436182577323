import { get, post, put, destroy } from '../api/api.js';

const baseUrl = 'customers';

function indexCustomers() {
  return get(baseUrl);
}

function showCustomers(id) {
  return get(`${baseUrl}/${id}`);
}

function storeCustomers(values) {
  return post(baseUrl, values);
}

function updateCustomers(id, values) {
  return put(`${baseUrl}/${id}`, values);
}

function destroyCustomers(id) {
  return destroy(`${baseUrl}/${id}`);


}

export { indexCustomers, showCustomers, storeCustomers, updateCustomers, destroyCustomers }